<template>
<div class="m-2 p-2 border border-red-200  rounded">
    <div class="m-2 p-2 border border-red-200  rounded text-xl text-yellow-800">
        Title: {{post.title}}
    </div>
    <div class="m-2 p-2 border border-red-200  rounded">
        <div>
            Content:
        </div>
        <div>
            {{post.body}}
        </div>
    </div>
</div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import store from '../store'

const ten = computed(() => { return useRoute().params.id })
const post = computed(() => {
  return store.getters.getAllPosts.find(x => x.id === parseInt(ten.value, 10))
})

</script>

<style>

</style>
