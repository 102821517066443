<template>
  <div class="text-orange-500 text-2xl m-4 items-center  divide-y divide-orange-400 md:divide-y-1">
    <div class="m-4">
      About Page
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
