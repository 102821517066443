<template>
  <div class="text-orange-500 text-2xl m-4 items-center  divide-y divide-orange-400 md:divide-y-1">
    <div class="m-4">
      Awesome BLOG
    </div>
    <div class="text-green-400 m-3">
      <router-link
        to="/app"
        class="m-4"
      >
        Home
      </router-link>
      <router-link
        to="/about"
        class="m-4"
      >
        About
      </router-link>
      <router-link
        to="/posts"
        class="m-4"
      >
        Blog
      </router-link>
    </div>
  </div>
  <router-view/>
</template>

<style lang="scss">

</style>
